import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53')
];

export const server_loads = [0,2,3,16,4,5,6,7,8,9,10,11,12,13,14];

export const dictionary = {
		"/": [~17],
		"/(app)/administration": [18,[2,3]],
		"/(app)/administration/reporting/user-log": [~19,[2,3]],
		"/(auth)/forgot-password": [~49,[15,16]],
		"/(auth)/forgot-password/[token]": [~50,[15,16]],
		"/(auth)/login": [~51,[15]],
		"/(auth)/logout": [52,[15]],
		"/(app)/profile/changePassword": [~20,[2,4]],
		"/(app)/profile/my-data": [21,[2,4]],
		"/(auth)/register": [~53,[15]],
		"/(app)/studies": [~22,[2,5]],
		"/(app)/studies/create-study": [48,[2,5]],
		"/(app)/studies/[id]": [~23,[2,5,6]],
		"/(app)/studies/[id]/edit-study": [24,[2,5,6]],
		"/(app)/studies/[id]/management/adjudication/create-tasks": [~37,[2,5,6]],
		"/(app)/studies/[id]/management/adjudication/worklist-management": [~38,[2,5,6]],
		"/(app)/studies/[id]/management/(study-setup)/automations": [~25,[2,5,6,7]],
		"/(app)/studies/[id]/management/(study-setup)/automations/tasks": [26,[2,5,6,7]],
		"/(app)/studies/[id]/management/(study-setup)/automations/tasks/add": [28,[2,5,6,7]],
		"/(app)/studies/[id]/management/(study-setup)/automations/tasks/[automation_id]/edit": [27,[2,5,6,7,8]],
		"/(app)/studies/[id]/management/(study-setup)/collaborators": [29,[2,5,6,9]],
		"/(app)/studies/[id]/management/(study-setup)/collaborators/add": [31,[2,5,6,9]],
		"/(app)/studies/[id]/management/(study-setup)/collaborators/[collaborator_id]/edit": [30,[2,5,6,9,10]],
		"/(app)/studies/[id]/management/(study-setup)/notifications": [32,[2,5,6]],
		"/(app)/studies/[id]/management/qc/create-tasks": [~39,[2,5,6]],
		"/(app)/studies/[id]/management/qc/worklist-management": [~40,[2,5,6]],
		"/(app)/studies/[id]/management/reads/create-tasks": [~41,[2,5,6]],
		"/(app)/studies/[id]/management/reads/worklist-management": [~42,[2,5,6]],
		"/(app)/studies/[id]/management/(study-setup)/sites": [33,[2,5,6,11]],
		"/(app)/studies/[id]/management/(study-setup)/sites/create": [35,[2,5,6,11]],
		"/(app)/studies/[id]/management/(study-setup)/sites/[site_id]/edit": [34,[2,5,6,11,12]],
		"/(app)/studies/[id]/management/(study-setup)/study-configuration": [36,[2,5,6]],
		"/(app)/studies/[id]/reporting/audit-trail": [~43,[2,5,6]],
		"/(app)/studies/[id]/reporting/queries": [~44,[2,5,6]],
		"/(app)/studies/[id]/worklist": [~45,[2,5,6]],
		"/(app)/studies/[id]/worklist/[task_id]/report": [~47,[2,5,6,13]],
		"/(app)/studies/[id]/worklist/[task_id]/[worksheet_id]": [46,[2,5,6,13,14]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';